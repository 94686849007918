import React from "react"
import Header from "../../components/header"
import {Helmet} from "react-helmet"

export default () => (
  <React.Fragment>
    <Helmet>
      <title>Commissioner Update - 2020 Season | Chode Dynasty</title>
    </Helmet>

    <Header />
    <main>
      <div className="row">
        <div className="inner">
          <h1>Commissioner Update - 2020 Season</h1>
          <nav>
            <ul>
              <li><a href="#draft">Draft</a></li>
              <li><a href="#changes">Changes</a></li>
              <li><a href="#rewards">Weekly Rewards</a></li>
            </ul>
          </nav>

          <h2 id="draft">Draft</h2>
          <p>The rookie draft date/time is TBD. We'll vote on a date/time as we get closer to the NFL Draft. It will be held in-person at the Commissioner's house, but you will be able to draft remotely using the Sleeper platform.</p>

          <h3>Details</h3>
          <p>The rookie draft will be 4 rounds. Draft order is listed below and will be linear, meaning that the order will be the same for all rounds. You're able to trade draft picks.</p>

          <p>Veterans/free agents will be available once the season starts, which should be immediately after the draft ends.</p>

          <h3>Order</h3>
          <p>
            <ol>
              <li>frankakatank</li>
              <li>Ckbenz</li>
              <li>Super Sportsball Bois</li>
              <li>Dinguses</li>
              <li>Lil' Jergz</li>
              <li>jhoffman</li>
              <li>Get Whipped</li>
              <li>Robo Blowies</li>
              <li>Get Nae Naed</li>
              <li>Jackson Jacks Off</li>
            </ol>
          </p>

          <h2 id="changes">Changes</h2>
          <h3>Draft Settings</h3>
          <p>We're increasing the draft pick time limit to 10 minutes. This is to provide plenty of time for in-draft trading, if players choose to.</p>

          <h3>League Payout Structure</h3>
          <p>We're adjusting the payout structure for the league. <strong>The league entry fee is not changing</strong>. Instead of using a portion of the entry money for draft party food, we're going to use that money to add an additional weekly reward (week 14) and a 3rd place prize.</p>

          <p>The new payout structure is as follows:
            <ul>
              <li>1st Place: $200 (50%)</li>
              <li>2nd Place: $40 (10%)</li>
              <li>3rd Place: $20 (5%)</li>
              <li>Weekly Reward Winners ($10/week x 14 weeks): $140 (35%)</li>
            </ul>
          </p>

          <h3>Waiver System Change</h3>
          <p>To make the waiver wire system more fair, we're changing to a Continuous Waivers schedule.</p>

          <p>Continuous Waivers means that there is no Free Agency, and our waivers clear at a set time each day so everybody has a chance to catch up on the news and make decisions on who to pick up from the available player pool.</p>

          <p>This setting change will make things more fair for league members who don't have immediate access to news. You will still make FAAB bids on players and waivers will run every day at 3AM EST.</p>

          <h3>COVID-19 Adjustments</h3>
          <p>We met as a league on 08-05-2020 to discuss adjustments for COVID-19. There are several adjustments that we approved unanimously:</p>

          <h4>Injured Reserve</h4>
          <ul>
            <li>Increase IR slots to the maximum 10 slots</li>
            <li>Allow players with COVID designation to be placed on IR</li>
            <li>Allow players with DNR/Opt-Out designation to be placed on IR</li>
          </ul>

          <p>These changes will be reverted at the end of the 2020 season.</p>

          <h4>If Season is Cancelled</h4>
          <p>The below details outline how we handle payouts and draft order for different scenarios:</p>

          <h5>Between Weeks 1 - 8</h5>
          <ul>
            <li>No payouts, money is moved to next year and no one owes dues for 2021</li>
            <li>Draft order is based on standings</li>
          </ul>

          <h5>Between Weeks 9 - 14</h5>
          <ul>
            <li>Payouts are based on standings</li>
            <li>Draft order is based on standings</li>
            <li>Any leftover weekly rewards money will be split between top 3 teams</li>
          </ul>

          <h5>Between Weeks 15 - 16 (fantasy playoffs)</h5>
          <ul>
            <li>Payouts are based on seed number in the playoff bracket</li>
            <li>Draft order is based on standings</li>
          </ul>
          
          <h2 id="rewards">Weekly Rewards</h2>
          <p>We're keeping the same weekly rewards as last year. I felt that the rewards worked really well. Since our regular season is now 14 weeks long (as of the 2019 season), we are adding a week 14 reward.</p>

          <p>
            <table className="styled rewards-cond">
              <thead>
                <th>Week</th>
                <th>Title</th>
                <th>Challenge</th>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ready Set Go</td>
                  <td>Team with the highest score</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Hero to Zero</td>
                  <td>Team with the biggest drop in score from Week 1 to Week 2</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Air It Out</td>
                  <td>Team with the QB with the longest pass</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Gotta Catch Em All</td>
                  <td>Team with the WR with the most receptions</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Runt of the Litter</td>
                  <td>Winning team with the lowest scoring starter</td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>Got There</td>
                  <td>Team that beats its opponent by the smallest margin of victory</td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Halftime</td>
                  <td>Team with the most total points after 7 weeks</td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>Blackjack</td>
                  <td>Team with a starter closest to 21 points without going over</td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>The Purge</td>
                  <td>Subtract last week's score from this weeks, the team with the highest remaining score wins</td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>Right On Target</td>
                  <td>Team closest to their projected score (over OR under)</td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>At Least You Tried</td>
                  <td>Highest scoring losing team</td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>Magic Legs</td>
                  <td>Team with the RB with the most rushing yards</td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>End Zone</td>
                  <td>Team with the most offensive touchdowns scored</td>
                </tr>

                <tr>
                  <td>14</td>
                  <td>Consolation Prize</td>
                  <td>Team with the most total points that didn't make the playoffs</td>
                </tr>
              </tbody>
            </table>
          </p>
        </div>
      </div>
    </main>
  </React.Fragment>
)